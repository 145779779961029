/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "utilities";
@import "print";
// custom imports
@import "fonts";
@import "custom-mixins";
@import "custom-functions";
@import "partials";
// custom Styles
// Back to top
#back-top {
    position: fixed;
    right: 100px;
    bottom: 30px;
    z-index: 2;
    @include media-breakpoint-down(md) {
        right: 30px;
    }
    a {
        width: 50px;
        display: block;
        text-align: center;
        font: 13px/100% Arial, Helvetica, sans-serif;
        line-height: 17px;
        text-transform: uppercase;
        text-decoration: none;
        color: #004A8D;
        /* background color transition */
        -webkit-transition: 1s;
        -moz-transition: 1s;
        transition: 1s;
        &:hover {
            color: #000;
            span {
                background-color: $blue;
            }
        }
    }
    span {
        width: 50px;
        height: 50px;
        display: block;
        margin-bottom: 7px;
        background: $blue url(../images/cd-top-arrow.svg) no-repeat center center;
        border: 2px solid $yellow;
        /* rounded corners */
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        /* background color transition */
        -webkit-transition: 1s;
        -moz-transition: 1s;
        transition: 1s;
    }
}


/// Carousel Fading
.carousel-fade{
    .carousel-item {
        opacity: 0;
        transition-duration: .6s;
        transition-property: opacity;
    }  
    .carousel-item.active, .carousel-item-next.carousel-item-left, .carousel-item-prev.carousel-item-right {
        opacity: 1;
    }
    .active.carousel-item-left, .active.carousel-item-right {
        opacity: 0;
    }
    .carousel-item-next, .carousel-item-prev, .carousel-item.active, .active.carousel-item-left, .active.carousel-item-prev {
        transform: translateX(0);
        transform: translate3d(0, 0, 0);
    }
} 
.carousel-wrapper{
    border-bottom: 50px solid $yellow;
    position: relative;
    margin-bottom: $hero-margin;
    @include media-breakpoint-down(md){
        margin-bottom: 2rem;
    }
    .fixed-image-wrapper{
        position: absolute;
        bottom: 10px;
        width: 100%;
        left: 0;
    }
}

.navbar-nav{
    font-size: 1rem;
    @include media-breakpoint-down(lg){
        font-size: 0.85rem;
    }
    margin-left: auto;
    .nav-link{
        border-bottom: 4px solid rgba($yellow,0);
        &.active{
            border-bottom-color: $yellow;
            @include media-breakpoint-down(md){
                border-bottom-color: transparent;
            }
        }
    }
    .active{
        .nav-link{
            /*border-bottom-color: $yellow;*/
        }
    }
}
.heading-block{
    @extend %section-margin;
    text-align: center;    
    h4{
        &::after{
            height: 5px;
            width: 136px;
            display: block;
            margin: 15px auto;
            background-color: $yellow;
            content: "";
        }
    }
    p{  
        font-weight: $medium;
      
    }
}
h1, .h1{
    font-weight: $semi-bold;
    sub{
        font-size: 6px;
        font-weight: $semi-bold;
    }
}
.heading1{
    @extend %heading1;
}
.key-factor-equine{
    @extend %section-margin;
    text-align: center;
    font-weight: $semi-bold;
}
sub{
    bottom: 0;
}
.points{
    @extend %section-margin;
    h5{
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(sm){
            margin-bottom: 0;
        }
    }
    .col-md-4{
        display: flex;
        flex-direction: column;
        @include media-breakpoint-down(sm){
            margin-bottom: 2rem;
            text-align: center;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    .text-wrapper{
        flex-grow: 1;
    }
}
.read-more{
    font-size: 0.9375rem;
    position: relative;
    z-index: 1;
    display: inline-block;
    padding: 0 15px 7px;
    font-weight: $semi-bold;
    &::after{
        content: "";
        height: 10px;
        width: 100%;
        position: absolute;
        z-index: -1;
        bottom: 8px;
        background-color: $yellow;
        left: 0;
        display: block;
    }
}
.who-benefit-wrapper{
    color: $white;
    background: url(../images/background-1.jpg) no-repeat bottom 65px center / cover;
    padding-top: 90px;
    text-align: center;
    margin-bottom: 150px;
    @include media-breakpoint-down(md){
        padding-top: 50px;
        margin-bottom: 50px;
    }
    .h1, h1{
        margin-bottom: 0.5rem;
    }
    .subheading{
        font-weight: $semi-bold;
    }
    .boxes-wrapper{
        margin-top: 50px;
        @include media-breakpoint-down(md){
            margin-top: 1.5rem;
        }
        .box{
            background-color: $yellow;
            padding: 2rem 2rem;
            display: flex;
            position: relative;
            align-items: center;
            color: $blue;
            text-align: center;
            margin-bottom: 1.5rem;
            box-shadow:  0 0 158px rgba($black,0.22);
            @include media-breakpoint-down(md){
                padding: 2rem 3rem;
                margin-bottom: 1.5rem;
            }
            @include media-breakpoint-down(sm){
                flex-wrap: wrap;
                padding: 3rem;
            }
            &:nth-child(2n){
                background-color: $white;
            }
            &:nth-child(2n + 1){
                .read-more::after{
                    background-color: $white;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            &::after{
               content: "";
                height: calc(100% - 10px);
                width: calc(100% - 10px);
                left: 5px;
                top: 5px;
                border: 3px solid $blue;
                display: block;
                position: absolute;
            }
            .audiance{
                font-weight: $extra-bold;
                font-size: 1.5rem;
                line-height: 1.1;
                padding-right: 0;   
                text-align: center;
                flex-grow: 1;
                @include media-breakpoint-down(lg){
                    
                    
                }
                @include media-breakpoint-down(md){
                    
                }
                @include media-breakpoint-down(sm){
                    width: 100%;
                    text-align: center;
                    margin-bottom: 0.5rem;
                    padding-right: 0;
                }
            }
            .description{
                font-weight: $semi-bold;
                flex: 0 1 auto;
                @include media-breakpoint-down(sm){
                    text-align: center;
                }
            }
        }
    }
}
.key-facts{
    margin-bottom: $section-margin;
    @include media-breakpoint-down(md){
        margin-bottom: 50px;
    }
    .heading-wrapper{
        text-align: center;
        max-width: 800px;
        margin: 0 auto $section-margin; 
        @include media-breakpoint-down(md){
            margin-bottom: 50px;    
        }
        p{            
            font-weight: $semi-bold;
            line-height: 1.2;
        }
    }
}
.fact-boxes-wrapper{
    .col-md-6{
        flex-grow: 1;
        flex-direction: column;
    }
    .box{
        background-color: $white;
        box-shadow:  0 0 158px rgba($black,0.1);
        margin: 0 7px;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        padding-bottom: 1.25rem;
        @include media-breakpoint-down(sm){
            margin-bottom: 2rem;
            height: auto;
        }
        img{
            width: 100%;
        }
        .img-wrapper{
            margin-bottom: 2.5rem;
            @include media-breakpoint-down(md){
                margin-bottom: 1rem;
            }
        }
        .text-wrapper{
            padding: 0 45px;
            text-align: center;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            .percent{
                font-size: 3.75rem;
                line-height: 1.2;
                font-weight: $semi-bold;
                sub{
                    font-size: 14px;
                }
                @include media-breakpoint-down(md){
                    font-size: 3rem;
                }
            }
            .desc{
                flex-grow: 1;
                line-height: 1.3;
                margin-bottom: 1rem;
                font-weight: $semi-bold;
            }
        }
    }
}
.more-information-wrapper{
    background: url(../images/background-2.jpg) no-repeat center top / cover;
    padding: $section-margin 0;
    text-align: center;
    color: $white;
    @include media-breakpoint-down(md){
        padding: 50px 0;
    }
    h1, .h1{
        margin-bottom: 1em;
    }
    .form-wrapper{
        max-width: 540px;
        margin: 0 auto;
        @extend %form;
    }
}
.footer-wrapper{
    color: $white;
    padding: 4rem 0 6rem;
    font-weight: $medium;
    @include media-breakpoint-down(md){        
        padding: 2rem 0;
        .col-lg-3{
            margin-bottom: 1rem;
        }
    }
    a{
        color: inherit;
    }
    h6{
        color: $yellow;
        text-transform: uppercase;
        margin-bottom: 1.25rem;
        font-weight: $medium;
        @include media-breakpoint-down(md){
            margin-bottom: 0.5rem;   
        }
    }
    ul{
        padding: 0;  
        list-style-type: none;
        line-height: 1.3;
        li{
            + li{
                margin-top: 1.25rem;
                @include media-breakpoint-down(md){
                    margin-top: 0.5rem;
                }
            }
        }
    }
}
.footer-logo{
    margin-bottom: 2rem;
}
.innerpage-banner{
    position: relative;
    margin-bottom: $section-margin;
    @include media-breakpoint-down(md){
        margin-bottom: 50px;
    }
    @include media-breakpoint-down(xs){
        margin-bottom: 2rem;
    }
    h1{
        font-weight: $black-font;
        font-size: 2.375rem;
        line-height: 1;
        text-transform: uppercase;
        letter-spacing: 0.22em;
        color: $white;
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        bottom: 35px;
        margin: 0;
        @include media-breakpoint-down(md){
            font-size: $h2-font-size;
        }
        @include media-breakpoint-down(xs){
            font-size: $h3-font-size;
            position: static;
            color: $yellow;
            background-color: $blue;
            padding: 0.5rem 1rem;
            font-weight: $bold;
        }
        
    }
}
.innerpage-text-block{
    font-size: 1rem;
    margin-bottom: $section-margin;
    @include media-breakpoint-down(md){
        font-size: 1rem;
        margin-bottom: 50px;
    }
    h1,.h1{
        text-align: center;
        margin-bottom: $section-margin/2;
        @include media-breakpoint-down(md){
            margin-bottom: $headings-margin-bottom;
        }
        span::after{
            top: 78%;
        }
    }
    .table-responsive{
        @media only screen and (min-width:900px){
            max-width: 800px;   
            margin: 0 auto 1rem;
        }        
        td:not(:first-child), th:not(:first-child){
            text-align: center;
        }
    }
}
.read-more-alt{
    font-size: 1.875rem;
    display: inline-block;
    background-color: $yellow;
    padding: 0.5rem 1.5rem;
    border-radius: 1.5rem;
    font-weight: $medium;
    margin: 2.5rem 0;
    @include media-breakpoint-down(md){
        font-size: 1.2rem;
        margin: 1rem 0;
    }
}
.applications{
    .heading-block{
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
        font-weight: $semi-bold;
         p{
            
        }
    }
}
.industry-players-wrapper{
    padding: 4.6875rem 0 8rem;
    @include media-breakpoint-down(md){
        padding: 2rem 0;
    }
    .heading-wrapper{
        max-width: 790px;
        margin: 0 auto $section-margin;
        font-size: 1.25rem;
        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        }
        h2{
            margin-bottom: 2rem;
        }
    }
}
.accordion{
    .card{
        border-top: 2px solid $yellow;
        border-bottom: 2px solid $yellow;
        &:first-of-type{
            border-bottom: 2px solid $yellow;
        }
    }
    .card-header{
        background-color: $blue;
        border-bottom: none;
        &.active-acc{
            background-color: $white;
            .btn-link{
                color: $blue;
                &::after{
                    content: "\f056";
                }
            }
        }
        .btn-link{
            width: 100%;
            color: $yellow;
            text-align: left;
            font-size: 1.5625rem;
            line-height: 1;
            font-weight: $extra-bold;
            position: relative;
            @include media-breakpoint-down(md){
                font-size: 1.2rem;
                padding-left: 0;
                padding-right: 0;
            }
            &::after{
                content: "\f055";
                font-family: $font-awesome;
                position: absolute;
                right: 0.75rem;
                top: 0.375rem;
            }
            &:hover, &:focus{
                text-decoration: none;
            }            
        }
    }

}
.dropdown-toggle::after{
    color: $yellow;
}
.navbar-nav .dropdown-menu{
    font-weight: $medium;
    font-size: 0.875rem;
}
.dropdown-item{
    &::after{
       display: inline-block;
        width: 8px;
        height: 13px;
        background: url(../images/chevron.png) no-repeat center center / 100% auto;
        content: "";
        vertical-align: -1px;
        margin-left: 0.5rem;
    }
}
@include media-breakpoint-down(md){
    .navbar-nav{
        .dropdown:hover{
            .dropdown-menu{
                display: block;
            }
        }
    }
}
.hidden{
    display: none;
}
.contact-form-wrapper{
    padding: $section-margin 190px;
    max-width: 980px;
    margin: 2rem auto $section-margin;
    background-color: $dark-blue;
    @include media-breakpoint-down(md){
        padding: 50px;
        margin: 2rem auto;
    }
    @include media-breakpoint-down(xs){
        padding: 20px;
    }
    form{
        @extend %form;
        .form-control{
            text-align: left;
            font-size: 1.25rem;
            background-color: rgba($white, 0.1);
        }
    }
}
.contact-details{
     padding: 50px 0;
}
.contact-info{
    display: flex;
    @include media-breakpoint-down(md){
        margin-bottom: 2rem;
    }
    .contact-icon{
        font-size: 2.5rem;
        color: $white;
        height: 100px;
        width: 100px;
        line-height: 100px;
        text-align: center;
        margin-right: 1.25rem;
        flex: 0 0 100px;
        background-color: $dark-blue;
        border-radius: 100%;
    }
    .detail{
        h5{
            margin-bottom: 0.5rem;
            letter-spacing: 0.2rem;
            font-weight: $black-font;
            span::after{
                background-color: $white;
                @include translate(0,0);
            }
        }
        p{
            font-weight: $semi-bold;
            margin-bottom: 0;
        }
    }
}
.impact #footer{
    margin-top: $section-margin;
    @include media-breakpoint-down(md){
        margin-top: 50px;
    }
}
#RecaptchaField2 > div{
    margin: 0 auto;
}