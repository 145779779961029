%heading1{
    span{
        display: inline-block;
        position: relative;
        z-index: 1;
        &::after{
            content: "";
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 0.6em;
            background-color: $yellow;
            left: 0;
            top: 50%;
            @include translate(0,-50%);
        }
    }
}
%section-margin{
    margin-bottom: $section-margin;    
    @include media-breakpoint-down(md){
        margin-bottom: 50px;
    }
}
%form{
    .form-control{
            text-align: center;
            font-weight: $medium;
            color: $white;
            font-size: 1.5625rem;
            padding: 1.5rem;
            background-color: darken($blue,10%);
            height: auto;
            border: none;
            @include media-breakpoint-down(md){
                font-size: 1.2rem;
                padding: 0.7rem 1rem;
            }
            @include placeholder{
                color: $white;
                opacity: 1;
            }
        }
        .form-group{
            margin-bottom: 1.5625rem;
            @include media-breakpoint-down(md){
                margin-bottom: 1rem;
            }
        }
        .btn{
            font-weight: $black-font;
            text-align: center;
            width: 100%;
            padding: 1.5rem;
            font-size: 1.5625rem;
            color: $blue;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
             @include media-breakpoint-down(md){
                font-size: 1.2rem;
                padding: 0.7rem 1rem;
            }
        }
}